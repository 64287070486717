import { API } from 'aws-amplify'
import { getBalance, getPayoutByCard, getPayoutByProviderId, getPayoutsByOrderId, getPayoutsByPayoutId, listPayoutsByDate, loadTransactionDetails } from '../../graphql/queries'
import { changeTransactionStatus, createPayout, updatePayout } from '../../graphql/mutations'
import { isValidRange } from '../../tools/validators'
import Logger from '../../tools/Logger'

const getDefaultState = () => {
  return {
    payouts: [],
    loading: false,
    nextNextToken: '',
    filter: {},
    selectedPayouts: [],
    balances: [],
    usdtBalance: '0.00',
    projectsBalances: [],
    balanceByDate: [],
  }
}

const state = getDefaultState()

const getters = {
  getPayouts: (state) => state.payouts,
  isLoading: (state) => state.loading,
  getNextNextToken: (state) => state.nextNextToken,
  getFilter: (state) => state.filter,
  getSelectedPayouts: (state) => state.selectedPayouts,
  balances: (state) => state.balances,
  usdtBalance: (state) => state.usdtBalance,
  projectsBalances: (state) => state.projectsBalances,
  getBalanceByDate: (state) => state.balanceByDate,
}

const actions = {
  async getPayoutsList({ commit }, { projectId, filter, nextToken, limit }) {
    commit('setLoading', true)
    try {
      const response = await API.graphql({
        query: listPayoutsByDate,
        variables: {
          projectId,
          ...filter,
          sortDirection: 'DESC',
          nextToken,
          limit,
        },
      })
      Logger.log(response)
      commit('setPayouts', response.data.listPayoutsByDate.items)
      commit('setLoading', false)
      commit('setNextNextToken', response.data.listPayoutsByDate.nextToken)
    } catch (err) {
      Logger.log(err)
      return {
        message: 'Something went wrong',
        success: false,
      }
    }
  },
  async getPayoutsByParameter({ commit }, { projectId, filter }) {
    // filterExpression - это условное обозначение филтра в данном случае, это не то же самое что filter поле
    const filterExpression = { eq: projectId }

    let query
    let responseQuery

    switch (filter.filterName) {
      case 'payout_id':
        query = getPayoutsByPayoutId
        responseQuery = 'getPayoutsByPayoutId'
        break
      case 'order_id':
        query = getPayoutsByOrderId
        responseQuery = 'getPayoutsByOrderId'
        break
      case 'provider_id':
        query = getPayoutByProviderId
        responseQuery = 'getPayoutByProviderId'
        break
      case 'card':
        query = getPayoutByCard
        responseQuery = 'getPayoutByCard'
        break
      // case 'transaction_status':
      //   // TODO: решить проблему с паджинацией, возможно для филтров отдельную функцию для загрузки платежей
      //   query = getPaymentsByTransactionStatus
      //   responseQuery = 'getPaymentsByTransactionStatus'
      //   break
      default:
        Logger.log('wrong filter field')
        return {
          message: 'You can filter by transaction ID or order ID',
          success: false,
        }
    }
    try {
      const request = {
        [filter.filterName]: filter.filterValue,
        projectId: filterExpression,
      }
      if (!projectId) delete request.projectId
      const response = await API.graphql({
        query,
        variables: request,
      })
      Logger.log(response)
      commit('setPayouts', response.data[`${responseQuery}`].items)
      commit('setNextNextToken', response.data[`${responseQuery}`].nextToken)
      commit('setFilter', { projectId: filterExpression })
    } catch (err) {
      Logger.log(err)
      return {
        message: 'Something went wrong',
        success: false,
      }
    }
  },
  async getPayoutsByRange({ commit }, { projectId, filter, limit }) {
    const filterExpression = {
      between: [new Date(filter.startDate + 'Z').toISOString(), new Date(filter.endDate + 'Z').toISOString()],
    }
    Logger.log(filterExpression)
    try {
      const response = await API.graphql({
        query: listPayoutsByDate,
        variables: {
          projectId,
          createdAt: filterExpression,
          sortDirection: 'DESC',
          limit,
        },
      })
      Logger.log(response)
      commit('setPayouts', response.data.listPayoutsByDate.items)
      commit('setNextNextToken', response.data.listPayoutsByDate.nextToken)
      commit('setFilter', { createdAt: filterExpression })
    } catch (err) {
      Logger.log(err)
      return {
        message: 'Something went wrong',
        success: false,
      }
    }
  },
  //eslint-disable-next-line
  async updatePayoutStatus({ commit }, payouts) {
    if (payouts.length === 0) {
      return {
        message: 'You should choose at least one payout',
        success: false,
      }
    }
    try {
      for await (const payout of payouts) {
        if (!/internal/.test(payout.payout_id)) {
          const data = {
            payout_id: payout.payout_id,
            project: payout.projectId,
          }

          const response = await API.graphql({
            query: updatePayout,
            variables: { input: data },
          })
          Logger.log(response)
          if (response && response.data.updatePayout.success === false) {
            return response.data.updatePayout
          }
          commit('updatePayouts', JSON.parse(response.data.updatePayout.result))
        }
      }
      return {
        message: 'Payouts has been updated',
        success: true,
      }
    } catch (err) {
      Logger.log(err)
      return {
        message: 'Something went wrong',
        success: false,
      }
    }
  },
  async bulkUpdatePayoutsStatus({ commit }, { ids, project }) {
    commit('setLoading', true)
    try {
      const transactions = []
      for (const id of ids) {
        if (!/internal/.test(id)) {
          const data = {
            payout_id: id,
            project: project,
          }

          const response = await API.graphql({
            query: updatePayout,
            variables: { input: data },
          })
          Logger.log(response)
          if (response && response.data.updatePayout.success === false) {
            // TODO: сохранять количество ошибок и потом отобразить
          } else {
            transactions.push(JSON.parse(response.data.updatePayout.result))
          }
        }
      }
      commit('setPayouts', transactions)
      commit('setLoading', false)
    } catch (err) {
      Logger.log(err)
      commit('setLoading', false)
      return {
        message: 'Something went wrong',
        success: false,
      }
    }
  },
  //eslint-disable-next-line
  async getPayoutsForCSV({ commit }, { projectId, filter, includeFailed }) {
    let nextToken = null
    let response
    let payoutsArr = []
    if (!isValidRange(filter)) {
      return {
        message: 'Out of range, you can set up to 7 days',
        success: false,
      }
    }

    const filterExpression = {
      between: [filter.startDate.toString(), filter.endDate.toString()],
    }
    try {
      do {
        response = await API.graphql({
          query: listPayoutsByDate,
          variables: {
            projectId,
            createdAt: filterExpression,
            sortDirection: 'DESC',
            limit: 1000,
            nextToken,
          },
        })
        Logger.log({ response })
        nextToken = response.data.listPayoutsByDate.nextToken
        payoutsArr.push(...response.data.listPayoutsByDate.items)
      } while (nextToken !== null)

      // filter payments
      if (!includeFailed) {
        payoutsArr = payoutsArr.filter((payout) => /completed/.test(payout.payout_status))
      }

      return payoutsArr.map((payout) => {
        let commission = null
        if (payout.amount && payout.full_amount) {
          commission = (+payout.full_amount - +payout.amount).toFixed(2)
        }
        const cardQiwi = payout.card ? payout.card : payout.qiwi ? payout.qiwi : null
        return [
          payout.payout_id,
          payout.transaction_type,
          payout.payout_status,
          payout.currency,
          payout.amount,
          commission,
          payout.order_id,
          cardQiwi,
          payout.user_name,
          payout.user_contact_email,
          payout.user_phone,
          payout.card,
          payout.createdAt,
          payout.updatedAt,
        ]
      })
    } catch (err) {
      Logger.log(err)
      return {
        message: 'Something went wrong',
        success: false,
      }
    }
  },
  async getBalance({ commit }, { project, isWallet }) {
    commit('setLoading', true)
    const res = await API.graphql({
      query: getBalance,
      variables: {
        input: { project, isWallet },
      },
    })
    Logger.log(res)
    commit('setLoading', false)
    commit('setBalance', res.data.getBalance)
  },
  // eslint-disable-next-line
  async getBalanceBulk({ commit, rootGetters }) {
    const promises = []
    const func = async (project) => {
      const res = await API.graphql({
        query: getBalance,
        variables: {
          input: { project: project.id },
        },
      })
      return {
        id: project.id.slice(0, 4),
        full_id: project.id,
        balance: res.data.getBalance.balances,
        merch_name: project.merchant_name,
        project_name: project.name,
      }
    }
    for (const project of rootGetters['projects/getProjects']) {
      promises.push(func(project))
    }
    const res = await Promise.all(promises)
    commit('setProjectBalances', res)
  },
  async balanceByDate({ commit, dispatch }, { project, date }) {
    commit('setLoading', true)
    const res = await API.graphql({
      query: getBalance,
      variables: {
        input: {
          project,
          date,
        },
      },
    })
    if (!res.data.getBalance.success) {
      dispatch('alert/setAlertData', { success: false, message: res.data.getBalance.message }, { root: true })
      commit('setLoading', false)
      return
    }
    commit('setBalanceByDate', res.data.getBalance.balanceByDate)
    commit('setLoading', false)
  },
  // eslint-disable-next-line
  async changeTransactionStatus({ commit, dispatch }, payload) {
    const res = await API.graphql({
      query: changeTransactionStatus,
      variables: {
        input: payload,
      },
    })
    return res.data.changeTransactionStatus
  },
  async createPayout({ dispatch }, payload) {
    try {
      const result = await API.graphql({
        query: createPayout,
        variables: {
          input: {
            fileId: payload.fileId,
            project: payload.project,
            code: payload.code,
          },
        },
      })
      dispatch('alert/setAlertData', { success: result.data.createPayout.success, message: result.data.createPayout.message }, { root: true })
    } catch (err) {
      Logger.log(err)
      dispatch('alert/setAlertData', { success: false, message: 'Unexpected Error' }, { root: true })
    }
  },
  async getPayoutDetails({ dispatch }, { transactionId, projectId }) {
    try {
      const transactionDetails = await API.graphql({
        query: loadTransactionDetails,
        variables: {
          input: {
            transactionId,
            projectId,
          },
        },
      })
      if (!transactionDetails.data.loadTransactionDetails.success) {
        dispatch('alert/setAlertData', { success: transactionDetails.data.success, message: transactionDetails.data.loadTransactionDetails.message }, { root: true })
        return
      }
      return JSON.parse(transactionDetails.data.loadTransactionDetails.result)
    } catch (err) {
      Logger.log(err)
      dispatch('alert/setAlertData', { success: false, message: 'Failed to fetch transaction info' }, { root: true })
    }
  },
  adjustProjectBalance({ commit }, payload) {
    commit('adjustProjectBalance', payload)
  },
  addToSelectedPayouts({ commit }, payout) {
    commit('addToSelectedPayouts', payout)
  },
  clearSelectedPayouts({ commit }) {
    commit('clearSelectedPayouts', [])
  },
  clearFilter({ commit }) {
    commit('clearFilter', {})
  },
  clearAllPayouts({ commit }) {
    commit('clearAllPayouts', [])
  },
  clearBalanceByDate({ commit }) {
    commit('clearBalanceByDate', [])
  },
}

const mutations = {
  setPayouts: (state, payouts) => (state.payouts = payouts),
  setNextNextToken: (state, token) => (state.nextNextToken = token),
  setLoading: (state, bool) => (state.loading = bool),
  setFilter: (state, filter) => (state.filter = filter),
  addToSelectedPayouts: (state, { checked, transaction }) => {
    if (state.selectedPayouts.length > 0) {
      state.selectedPayouts = state.selectedPayouts.filter((p) => p.payout_id !== transaction.payout_id)
      if (checked) state.selectedPayouts = [...state.selectedPayouts, transaction]
    } else {
      state.selectedPayouts = [...state.selectedPayouts, transaction]
    }
  },
  clearSelectedPayouts: (state, payload) => (state.selectedPayouts = payload),
  clearFilter: (state, filter) => (state.filter = filter),
  clearAllPayouts: (state, payload) => (state.payouts = payload),
  setBalance: (state, payload) => ((state.balances = payload.balances), (state.usdtBalance = payload.usdtBalance)),
  setProjectBalances: (state, payload) => (state.projectsBalances = payload),
  updatePayouts: (state, payload) => {
    state.payouts = state.payouts.map((payout) => {
      if (payout.payout_id === payload.payout_id) {
        return payload
      }
      return payout
    })
  },
  adjustProjectBalance: (state, payload) => {
    state.projectsBalances = state.projectsBalances.map((pr) => {
      if (pr.full_id === payload.id) {
        pr.balance.forEach((b) => {
          if (b.currency === payload.currency) {
            b.available_balance = payload.new_balance
          }
        })
      }
      return pr
    })
  },
  setBalanceByDate: (state, payload) => {
    state.balanceByDate = payload
  },
  clearBalanceByDate: (state, payload) => {
    state.balanceByDate = payload
  },
  clearState: (state) => {
    Object.assign(state, getDefaultState())
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
